<script>
import { formatDate } from "@/utils";

export default {
    name: "PrettyDate",
    functional: true,
    props: {
        date: {
            type: String,
            required: true
        }
    },
    render(h, { props }) {
        const { date } = props;
        
        return h("div", { class: "pretty-date" }, [
            h("span", { class: "footnote" }, `${ formatDate(date, "MMMM dd, yyyy") }`)
        ]);
    }
}
</script>
